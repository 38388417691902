import React from "react"
import PropTypes from "prop-types"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

const GuideArticlesCard = ({ guide, isLast }) => {
  return (
    <div
      className={classNames("row flex-column flex-md-row align-items-lg-center pt-5 overflow-hidden", {
        'mb-3': !isLast
      })}
    >
      <div
        className="col-12 col-lg-8 pr-lg-8">
        <div className="d-flex flex-column justify-content-center">
          <p className="text-uppercase">Part {guide.frontmatter.guideStep} →</p>
          <Link to={guide.fields.slug} className="mb-2 text-black">
            <h2 className="font-medium font-size-lg-5">
              {guide.frontmatter.title}
            </h2>
          </Link>
          <p>{guide.excerpt}</p>
        </div>
      </div>
      <div className="col-10 col-lg-4">
        <Link to={guide.fields.slug}>
          <Img
            fluid={guide.frontmatter.featuredimage.childImageSharp.fluid}
          />
        </Link>
      </div>
      {
        !isLast ? (
          <div className="col-12">
            <div class="w-100 pt-5 border-bottom"></div>
          </div>
        ) : ''
      }
    </div>
  )
}

const GuideArticlesRoll = ({ limit, guideMain }) => {
  const data = useStaticQuery(graphql`
    query GuideArticlesRollQuery {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { templateKey: { eq: "article-post" }, isGuide: { eq: true } } }
      ) {
        edges {
          node {
            excerpt(pruneLength: 200)
            id
            fields {
              slug
            }
            frontmatter {
              title
              templateKey
              guideMain
              guideStep
              date(formatString: "DD MMMM YYYY")
              featuredpost
              featuredimage {
                childImageSharp {
                  fluid(maxWidth: 300, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { edges } = data.allMarkdownRemark;
  const guides = edges.filter(edge => edge.node.frontmatter.guideMain === guideMain);
  const orderedGuides = guides.sort((a, b) => a.node.frontmatter.guideStep - b.node.frontmatter.guideStep);

  return (
    <div className="container">
      {orderedGuides &&
        orderedGuides.map(({ node: guide }, i) => {
          const isLast = i === orderedGuides.length - 1;

          return <GuideArticlesCard key={guide.id} guide={guide} isLast={isLast} />
        })}
    </div>
  )
}

GuideArticlesRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  colCount: PropTypes.number,
}

export default GuideArticlesRoll

import React from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import GuideArticlesRoll from "../../components/GuideArticlesRoll"

const CremationGuides = ({ path }) => {
  return (
    <Layout loadMap={false} hasSubNav={true} navId="cremation">
      <SEO
        path={path}
        title="Affordable Pricing Estates Plus | Pricing of Deceased Estate Solicitors"
        description="Save on legal fees. Most affordable pricing. Prepare PROBATE and LETTERS OFADMINISTRATION in a hassle-free manner. Get in touch to find out more aboutESTATES PLUS Prices."
      />
      <section className="bg-white section-gap">
        <div className="container">
          <h2 className="mb-3 font-bold">How to Arrange a No-Fuss Direct Cremation</h2>
          <p className="mb-lg-7">
            We write and curate helpful content to help you deal with Deceased
            Estates and Wills in Australia
        </p>
        </div>
        <GuideArticlesRoll guideMain="cremation"/>
      </section>
    </Layout>
  )
}

export default CremationGuides
